<script>
  /**
   * tempo
   * © 2020, Hendrik Runte
   **/

  export let users_unique_id;

  import { version } from './lib/version.js';
  import { log } from './lib/log.js';

  import { onMount } from 'svelte';

  import {
    fetchUser,
    fetchActivities,
    fetchProjects,
    user,
  } from './lib/store.js';

  // import Login from './components/Login.svelte';
  import Calendar from './components/Calendar.svelte';
  import EditActivities from './components/EditActivities.svelte';
  import ActivitiesList from './components/ActivitiesList.svelte';
  import ProjectsDailySums from './components/ProjectsDailySums.svelte';
  import ProjectsMonthlySums from './components/ProjectsMonthlySums.svelte';
  import Revision from './components/Revision.svelte';

  // const users_unique_id = '9e0d57d2-29af-11eb-88ec-20e2a8b7de8d';

  // const DEBUG = {"env":{"isProd":true,"API_HOST":"https://tempo.hendrikrunte.de"}}.env.isProd ? false : true;
  // const DEBUG = true;
  // const log = DEBUG ? console.log.bind(console) : function () {};

  log(
    '%cThis is the Tempo client. It`s assembled with svelte.',
    'background-color: fuchsia; color: black; padding: 0 1em'
  );

  log(
    '%cMODE: %s',
    'background-color: fuchsia; color: black; padding: 0 1em',
    {"env":{"isProd":true,"API_HOST":"https://tempo.hendrikrunte.de"}}.env.isProd ? 'PRODUCTION' : 'DEV'
  );

  log(
    '%cAPI_HOST: %s',
    'background-color: fuchsia; color: black; padding: 0 1em',
    {"env":{"isProd":true,"API_HOST":"https://tempo.hendrikrunte.de"}}.env.API_HOST
  );

  onMount(async () => {
    await fetchUser(users_unique_id);
    await fetchProjects(users_unique_id);
    await fetchActivities(users_unique_id, new Date());
  });
</script>

<!--
	Mind the 'global' attribute!
	This is needed to let 'prependData' work.
	See rollup.config.js
-->
<style global lang="scss">@charset "UTF-8";
/**
 * typography
 *
 */
:global(html) {
  font-family: system, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", "Lucida Grande", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 62.5%;
}

:global(body) {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 130%;
  color: #e6e6e6;
}

/**
 * keyframes
 */
@-webkit-keyframes -global-fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opactiy: 1;
  }
}
@keyframes -global-fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opactiy: 1;
  }
}
@-webkit-keyframes -global-slipIn {
  0% {
    opacity: 0.5;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes -global-slipIn {
  0% {
    opacity: 0.5;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@-webkit-keyframes -global-slideInLeft {
  0% {
    opacity: 0.5;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes -global-slideInLeft {
  0% {
    opacity: 0.5;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@-webkit-keyframes -global-slideInRight {
  0% {
    opacity: 0.5;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes -global-slideInRight {
  0% {
    opacity: 0.5;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
/**
 * common
 *
 * All common style settings go here.
 * For font-family and other typographic stuff
 * see _typography.scss
 */
:global(html) {
  box-sizing: border-box;
  scroll-behavior: smooth;
  background-color: #666666;
  color: white;
}

:global(*),
:global(*:before),
:global(*:after) {
  box-sizing: inherit;
}

:global(html),
:global(body) {
  height: 100%;
}

:global(body) {
  margin: 0;
}

:global(.fadein) {
  -webkit-animation: 0.5s ease-out 0s 1 fadeIn;
          animation: 0.5s ease-out 0s 1 fadeIn;
}

:global(h1),
:global(h2),
:global(h3),
:global(h4),
:global(h5),
:global(h6) {
  margin: 0;
  font-weight: 600;
}

:global(h1) {
  display: flex;
  justify-content: space-between;
  color: white;
}
:global(h1) :global(span::before) {
  content: ":: ";
}

:global(h2) {
  color: #b3b3b3;
}

:global(h2::before) {
  content: ":: ";
}

:global(p) {
  margin: 0 0 1em 0;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
}

:global(q) {
  quotes: "»" "«" "›" "‹";
}
:global(q:lang(fr)) {
  quotes: "«" "»" "‹" "›";
}
:global(q:lang(en)) {
  quotes: "“" "”" "‘" "’";
}
:global(q:lang(ch)) {
  quotes: "» " " «" "› " " ‹";
}

:global(blockquote) {
  margin: 0;
  padding: 0;
}

:global(a:-webkit-any-link) {
  text-decoration: none;
}

:global(a:-moz-any-link) {
  text-decoration: none;
}

:global(a:any-link) {
  text-decoration: none;
}

:global(ul),
:global(li),
:global(dl),
:global(dt),
:global(dd) {
  padding: 0;
  margin: 0;
  list-style: none;
}

:global(#tempo) {
  margin: 0;
  padding: 1em;
  background-color: rgba(0, 0, 0, 0.7);
  position: relative;
}
:global(#tempo) :global(.menulet) {
  border: 1px solid #666666;
  border-radius: 9px;
  margin: 0.4em 1em;
  width: 420px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
}

:global(h1),
:global(h2) {
  font-size: 1em;
}

:global(h2) {
  padding: 0 0 0.4em 0;
}

:global(header),
:global(section),
:global(footer) {
  padding: 0 0 0.8em 0;
  margin: 0 0 0.8em 0;
  border-bottom: 1px solid #666666;
}

:global(section[name=assignments]) {
  border-bottom: 1px solid #666666;
}

:global(section[name=projects]) {
  border-bottom: 1px solid #666666;
}

:global(section[name=revision]) {
  margin-top: 2em;
  display: block;
  color: white;
  font-family: "Courier New", Courier, monospace;
  font-weight: normal;
  font-size: 0.7em;
  padding: 0.4em 0;
}
:global(section[name=revision]) :global(p) {
  width: 100%;
  margin: 0 auto;
}

:global(section[name=activities_list]) :global(h2) {
  transition: 0.4s all ease-in-out;
}
:global(section[name=activities_list]) :global(h2.is_fetching) {
  color: #2c6ced;
}
:global(section[name=activities_list]) :global(h2.is_fetching::after) {
  content: " …";
}

:global(.shortcode) {
  position: relative;
  text-transform: uppercase;
  font-size: 0.8em;
  margin: 0 0.4em;
  padding: 0 0.2em 0 0.8em;
  display: inline-block;
  font-weight: bold;
  width: 3em;
}
:global(.shortcode::before) {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0.4em;
  height: 100%;
  border-radius: 3px;
}
:global(.shortcode.marker-blue::before) {
  background-color: #00dcf2;
}
:global(.shortcode.marker-fuchsia::before) {
  background-color: #f9009a;
}
:global(.shortcode.marker-green::before) {
  background-color: #04e900;
}
:global(.shortcode.marker-yellow::before) {
  background-color: #ffff00;
}
:global(.shortcode.marker-salmon::before) {
  background-color: #ff6272;
}
:global(.shortcode.marker-orange::before) {
  background-color: #ff9100;
}

:global(a:-webkit-any-link) {
  color: white;
  text-decoration: none;
  display: block;
  border-radius: 3px;
  padding: 0.5em 0.8em;
  white-space: nowrap;
}

:global(a:-moz-any-link) {
  color: white;
  text-decoration: none;
  display: block;
  border-radius: 3px;
  padding: 0.5em 0.8em;
  white-space: nowrap;
}

:global(a:any-link) {
  color: white;
  text-decoration: none;
  display: block;
  border-radius: 3px;
  padding: 0.5em 0.8em;
  white-space: nowrap;
}
:global(a:-webkit-any-link.delete) {
  margin: 3px 12px 0 0;
  position: relative;
  width: 26px;
  height: 26px;
  line-height: 2000%;
  overflow: hidden;
  display: block;
}
:global(a:-moz-any-link.delete) {
  margin: 3px 12px 0 0;
  position: relative;
  width: 26px;
  height: 26px;
  line-height: 2000%;
  overflow: hidden;
  display: block;
}
:global(a:any-link.delete) {
  margin: 3px 12px 0 0;
  position: relative;
  width: 26px;
  height: 26px;
  line-height: 2000%;
  overflow: hidden;
  display: block;
}
:global(a:-webkit-any-link.delete::before), :global(a:-webkit-any-link.delete::after) {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 26px;
  height: 26px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
:global(a:-moz-any-link.delete::before), :global(a:-moz-any-link.delete::after) {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 26px;
  height: 26px;
  transform: translateY(-50%);
}
:global(a:any-link.delete::before), :global(a:any-link.delete::after) {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 26px;
  height: 26px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
:global(a:-webkit-any-link.delete::before) {
  border-radius: 50%;
  background-color: #4d4d4d;
}
:global(a:-moz-any-link.delete::before) {
  border-radius: 50%;
  background-color: #4d4d4d;
}
:global(a:any-link.delete::before) {
  border-radius: 50%;
  background-color: #4d4d4d;
}
:global(a:-webkit-any-link.delete::after) {
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22448%22%20height%3D%22512%22%20viewBox%3D%220%200%20448%20512%22%3E%0A%20%20%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M296%2C432%20L312%2C432%20C316.418278%2C432%20320%2C428.418278%20320%2C424%20L320%2C152%20C320%2C147.581722%20316.418278%2C144%20312%2C144%20L296%2C144%20C291.581722%2C144%20288%2C147.581722%20288%2C152%20L288%2C424%20C288%2C428.418278%20291.581722%2C432%20296%2C432%20Z%20M136%2C432%20L152%2C432%20C156.418278%2C432%20160%2C428.418278%20160%2C424%20L160%2C152%20C160%2C147.581722%20156.418278%2C144%20152%2C144%20L136%2C144%20C131.581722%2C144%20128%2C147.581722%20128%2C152%20L128%2C424%20C128%2C428.418278%20131.581722%2C432%20136%2C432%20Z%20M440%2C64%20L336%2C64%20L302.4%2C19.2%20C293.33499%2C7.11331955%20279.108351%2C0%20264%2C0%20L184%2C0%20C168.891649%2C0%20154.66501%2C7.11331955%20145.6%2C19.2%20L112%2C64%20L8%2C64%20C3.581722%2C64%200%2C67.581722%200%2C72%20L0%2C88%20C0%2C92.418278%203.581722%2C96%208%2C96%20L32%2C96%20L32%2C464%20C32%2C490.509668%2053.490332%2C512%2080%2C512%20L368%2C512%20C394.509668%2C512%20416%2C490.509668%20416%2C464%20L416%2C96%20L440%2C96%20C444.418278%2C96%20448%2C92.418278%20448%2C88%20L448%2C72%20C448%2C67.581722%20444.418278%2C64%20440%2C64%20Z%20M171.2%2C38.4%20C174.231944%2C34.383436%20178.967583%2C32.0156166%20184%2C32%20L264%2C32%20C269.032417%2C32.0156166%20273.768056%2C34.383436%20276.8%2C38.4%20L296%2C64%20L152%2C64%20L171.2%2C38.4%20Z%20M384%2C464%20C384%2C472.836556%20376.836556%2C480%20368%2C480%20L80%2C480%20C71.163444%2C480%2064%2C472.836556%2064%2C464%20L64%2C96%20L384%2C96%20L384%2C464%20Z%20M216%2C432%20L232%2C432%20C236.418278%2C432%20240%2C428.418278%20240%2C424%20L240%2C152%20C240%2C147.581722%20236.418278%2C144%20232%2C144%20L216%2C144%20C211.581722%2C144%20208%2C147.581722%20208%2C152%20L208%2C424%20C208%2C428.418278%20211.581722%2C432%20216%2C432%20Z%22%2F%3E%0A%3C%2Fsvg%3E%0A");
  background-size: 50% 50%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  -webkit-filter: invert(100%);
          filter: invert(100%);
}
:global(a:-moz-any-link.delete::after) {
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22448%22%20height%3D%22512%22%20viewBox%3D%220%200%20448%20512%22%3E%0A%20%20%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M296%2C432%20L312%2C432%20C316.418278%2C432%20320%2C428.418278%20320%2C424%20L320%2C152%20C320%2C147.581722%20316.418278%2C144%20312%2C144%20L296%2C144%20C291.581722%2C144%20288%2C147.581722%20288%2C152%20L288%2C424%20C288%2C428.418278%20291.581722%2C432%20296%2C432%20Z%20M136%2C432%20L152%2C432%20C156.418278%2C432%20160%2C428.418278%20160%2C424%20L160%2C152%20C160%2C147.581722%20156.418278%2C144%20152%2C144%20L136%2C144%20C131.581722%2C144%20128%2C147.581722%20128%2C152%20L128%2C424%20C128%2C428.418278%20131.581722%2C432%20136%2C432%20Z%20M440%2C64%20L336%2C64%20L302.4%2C19.2%20C293.33499%2C7.11331955%20279.108351%2C0%20264%2C0%20L184%2C0%20C168.891649%2C0%20154.66501%2C7.11331955%20145.6%2C19.2%20L112%2C64%20L8%2C64%20C3.581722%2C64%200%2C67.581722%200%2C72%20L0%2C88%20C0%2C92.418278%203.581722%2C96%208%2C96%20L32%2C96%20L32%2C464%20C32%2C490.509668%2053.490332%2C512%2080%2C512%20L368%2C512%20C394.509668%2C512%20416%2C490.509668%20416%2C464%20L416%2C96%20L440%2C96%20C444.418278%2C96%20448%2C92.418278%20448%2C88%20L448%2C72%20C448%2C67.581722%20444.418278%2C64%20440%2C64%20Z%20M171.2%2C38.4%20C174.231944%2C34.383436%20178.967583%2C32.0156166%20184%2C32%20L264%2C32%20C269.032417%2C32.0156166%20273.768056%2C34.383436%20276.8%2C38.4%20L296%2C64%20L152%2C64%20L171.2%2C38.4%20Z%20M384%2C464%20C384%2C472.836556%20376.836556%2C480%20368%2C480%20L80%2C480%20C71.163444%2C480%2064%2C472.836556%2064%2C464%20L64%2C96%20L384%2C96%20L384%2C464%20Z%20M216%2C432%20L232%2C432%20C236.418278%2C432%20240%2C428.418278%20240%2C424%20L240%2C152%20C240%2C147.581722%20236.418278%2C144%20232%2C144%20L216%2C144%20C211.581722%2C144%20208%2C147.581722%20208%2C152%20L208%2C424%20C208%2C428.418278%20211.581722%2C432%20216%2C432%20Z%22%2F%3E%0A%3C%2Fsvg%3E%0A");
  background-size: 50% 50%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  filter: invert(100%);
}
:global(a:any-link.delete::after) {
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22448%22%20height%3D%22512%22%20viewBox%3D%220%200%20448%20512%22%3E%0A%20%20%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M296%2C432%20L312%2C432%20C316.418278%2C432%20320%2C428.418278%20320%2C424%20L320%2C152%20C320%2C147.581722%20316.418278%2C144%20312%2C144%20L296%2C144%20C291.581722%2C144%20288%2C147.581722%20288%2C152%20L288%2C424%20C288%2C428.418278%20291.581722%2C432%20296%2C432%20Z%20M136%2C432%20L152%2C432%20C156.418278%2C432%20160%2C428.418278%20160%2C424%20L160%2C152%20C160%2C147.581722%20156.418278%2C144%20152%2C144%20L136%2C144%20C131.581722%2C144%20128%2C147.581722%20128%2C152%20L128%2C424%20C128%2C428.418278%20131.581722%2C432%20136%2C432%20Z%20M440%2C64%20L336%2C64%20L302.4%2C19.2%20C293.33499%2C7.11331955%20279.108351%2C0%20264%2C0%20L184%2C0%20C168.891649%2C0%20154.66501%2C7.11331955%20145.6%2C19.2%20L112%2C64%20L8%2C64%20C3.581722%2C64%200%2C67.581722%200%2C72%20L0%2C88%20C0%2C92.418278%203.581722%2C96%208%2C96%20L32%2C96%20L32%2C464%20C32%2C490.509668%2053.490332%2C512%2080%2C512%20L368%2C512%20C394.509668%2C512%20416%2C490.509668%20416%2C464%20L416%2C96%20L440%2C96%20C444.418278%2C96%20448%2C92.418278%20448%2C88%20L448%2C72%20C448%2C67.581722%20444.418278%2C64%20440%2C64%20Z%20M171.2%2C38.4%20C174.231944%2C34.383436%20178.967583%2C32.0156166%20184%2C32%20L264%2C32%20C269.032417%2C32.0156166%20273.768056%2C34.383436%20276.8%2C38.4%20L296%2C64%20L152%2C64%20L171.2%2C38.4%20Z%20M384%2C464%20C384%2C472.836556%20376.836556%2C480%20368%2C480%20L80%2C480%20C71.163444%2C480%2064%2C472.836556%2064%2C464%20L64%2C96%20L384%2C96%20L384%2C464%20Z%20M216%2C432%20L232%2C432%20C236.418278%2C432%20240%2C428.418278%20240%2C424%20L240%2C152%20C240%2C147.581722%20236.418278%2C144%20232%2C144%20L216%2C144%20C211.581722%2C144%20208%2C147.581722%20208%2C152%20L208%2C424%20C208%2C428.418278%20211.581722%2C432%20216%2C432%20Z%22%2F%3E%0A%3C%2Fsvg%3E%0A");
  background-size: 50% 50%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  -webkit-filter: invert(100%);
          filter: invert(100%);
}
:global(a:-webkit-any-link.delete:hover) {
  background-color: transparent;
}
:global(a:-moz-any-link.delete:hover) {
  background-color: transparent;
}
:global(a:any-link.delete:hover) {
  background-color: transparent;
}
:global(a:-webkit-any-link.delete:hover::before) {
  background-color: #cc0000;
}
:global(a:-moz-any-link.delete:hover::before) {
  background-color: #cc0000;
}
:global(a:any-link.delete:hover::before) {
  background-color: #cc0000;
}
:global(a:-webkit-any-link.activity) {
  position: relative;
  padding-left: 36px;
  margin-right: 0.4em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
}
:global(a:-moz-any-link.activity) {
  position: relative;
  padding-left: 36px;
  margin-right: 0.4em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
}
:global(a:any-link.activity) {
  position: relative;
  padding-left: 36px;
  margin-right: 0.4em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
}
:global(a:-webkit-any-link.activity::before), :global(a:-webkit-any-link.activity::after) {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 26px;
  height: 26px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
:global(a:-moz-any-link.activity::before), :global(a:-moz-any-link.activity::after) {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 26px;
  height: 26px;
  transform: translateY(-50%);
}
:global(a:any-link.activity::before), :global(a:any-link.activity::after) {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 26px;
  height: 26px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
:global(a:-webkit-any-link.activity::before) {
  border-radius: 50%;
  background-color: #4d4d4d;
}
:global(a:-moz-any-link.activity::before) {
  border-radius: 50%;
  background-color: #4d4d4d;
}
:global(a:any-link.activity::before) {
  border-radius: 50%;
  background-color: #4d4d4d;
}
:global(a:-webkit-any-link.activity::after) {
  background-image: url("data:image/svg+xml,%3Csvg%20aria-hidden%3D%22true%22%20data-prefix%3D%22fal%22%20data-icon%3D%22calendar-check%22%20class%3D%22svg-inline--fa%20fa-calendar-check%20fa-w-14%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20448%20512%22%3E%3Cpath%20fill%3D%22currentColor%22%20d%3D%22M400%2064h-48V12c0-6.627-5.373-12-12-12h-8c-6.627%200-12%205.373-12%2012v52H128V12c0-6.627-5.373-12-12-12h-8c-6.627%200-12%205.373-12%2012v52H48C21.49%2064%200%2085.49%200%20112v352c0%2026.51%2021.49%2048%2048%2048h352c26.51%200%2048-21.49%2048-48V112c0-26.51-21.49-48-48-48zM48%2096h352c8.822%200%2016%207.178%2016%2016v48H32v-48c0-8.822%207.178-16%2016-16zm352%20384H48c-8.822%200-16-7.178-16-16V192h384v272c0%208.822-7.178%2016-16%2016zm-66.467-194.937l-134.791%20133.71c-4.7%204.663-12.288%204.642-16.963-.046l-67.358-67.552c-4.683-4.697-4.672-12.301.024-16.985l8.505-8.48c4.697-4.683%2012.301-4.672%2016.984.024l50.442%2050.587%20117.782-116.837c4.709-4.671%2012.313-4.641%2016.985.068l8.458%208.527c4.672%204.709%204.641%2012.313-.068%2016.984z%22%2F%3E%3C%2Fsvg%3E");
  background-size: 50% 50%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  -webkit-filter: invert(100%);
          filter: invert(100%);
}
:global(a:-moz-any-link.activity::after) {
  background-image: url("data:image/svg+xml,%3Csvg%20aria-hidden%3D%22true%22%20data-prefix%3D%22fal%22%20data-icon%3D%22calendar-check%22%20class%3D%22svg-inline--fa%20fa-calendar-check%20fa-w-14%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20448%20512%22%3E%3Cpath%20fill%3D%22currentColor%22%20d%3D%22M400%2064h-48V12c0-6.627-5.373-12-12-12h-8c-6.627%200-12%205.373-12%2012v52H128V12c0-6.627-5.373-12-12-12h-8c-6.627%200-12%205.373-12%2012v52H48C21.49%2064%200%2085.49%200%20112v352c0%2026.51%2021.49%2048%2048%2048h352c26.51%200%2048-21.49%2048-48V112c0-26.51-21.49-48-48-48zM48%2096h352c8.822%200%2016%207.178%2016%2016v48H32v-48c0-8.822%207.178-16%2016-16zm352%20384H48c-8.822%200-16-7.178-16-16V192h384v272c0%208.822-7.178%2016-16%2016zm-66.467-194.937l-134.791%20133.71c-4.7%204.663-12.288%204.642-16.963-.046l-67.358-67.552c-4.683-4.697-4.672-12.301.024-16.985l8.505-8.48c4.697-4.683%2012.301-4.672%2016.984.024l50.442%2050.587%20117.782-116.837c4.709-4.671%2012.313-4.641%2016.985.068l8.458%208.527c4.672%204.709%204.641%2012.313-.068%2016.984z%22%2F%3E%3C%2Fsvg%3E");
  background-size: 50% 50%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  filter: invert(100%);
}
:global(a:any-link.activity::after) {
  background-image: url("data:image/svg+xml,%3Csvg%20aria-hidden%3D%22true%22%20data-prefix%3D%22fal%22%20data-icon%3D%22calendar-check%22%20class%3D%22svg-inline--fa%20fa-calendar-check%20fa-w-14%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20448%20512%22%3E%3Cpath%20fill%3D%22currentColor%22%20d%3D%22M400%2064h-48V12c0-6.627-5.373-12-12-12h-8c-6.627%200-12%205.373-12%2012v52H128V12c0-6.627-5.373-12-12-12h-8c-6.627%200-12%205.373-12%2012v52H48C21.49%2064%200%2085.49%200%20112v352c0%2026.51%2021.49%2048%2048%2048h352c26.51%200%2048-21.49%2048-48V112c0-26.51-21.49-48-48-48zM48%2096h352c8.822%200%2016%207.178%2016%2016v48H32v-48c0-8.822%207.178-16%2016-16zm352%20384H48c-8.822%200-16-7.178-16-16V192h384v272c0%208.822-7.178%2016-16%2016zm-66.467-194.937l-134.791%20133.71c-4.7%204.663-12.288%204.642-16.963-.046l-67.358-67.552c-4.683-4.697-4.672-12.301.024-16.985l8.505-8.48c4.697-4.683%2012.301-4.672%2016.984.024l50.442%2050.587%20117.782-116.837c4.709-4.671%2012.313-4.641%2016.985.068l8.458%208.527c4.672%204.709%204.641%2012.313-.068%2016.984z%22%2F%3E%3C%2Fsvg%3E");
  background-size: 50% 50%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  -webkit-filter: invert(100%);
          filter: invert(100%);
}
:global(a:-webkit-any-link.activity:hover::before) {
  background-color: #6b6b6b;
}
:global(a:-moz-any-link.activity:hover::before) {
  background-color: #6b6b6b;
}
:global(a:any-link.activity:hover::before) {
  background-color: #6b6b6b;
}
:global(a:-webkit-any-link.activity.selected::before) {
  background-color: #2c6ced;
}
:global(a:-moz-any-link.activity.selected::before) {
  background-color: #2c6ced;
}
:global(a:any-link.activity.selected::before) {
  background-color: #2c6ced;
}
:global(a:-webkit-any-link.activity) :global(span) {
  display: inline-block;
}
:global(a:-moz-any-link.activity) :global(span) {
  display: inline-block;
}
:global(a:any-link.activity) :global(span) {
  display: inline-block;
}
:global(a:-webkit-any-link.activity) :global(.shortcode) {
  flex-basis: 12%;
  margin: 0 0.6em 0 0;
  padding: 0 0.2em 0 0.8em;
  border-radius: 2px;
}
:global(a:-moz-any-link.activity) :global(.shortcode) {
  flex-basis: 12%;
  margin: 0 0.6em 0 0;
  padding: 0 0.2em 0 0.8em;
  border-radius: 2px;
}
:global(a:any-link.activity) :global(.shortcode) {
  flex-basis: 12%;
  margin: 0 0.6em 0 0;
  padding: 0 0.2em 0 0.8em;
  border-radius: 2px;
}
:global(a:-webkit-any-link.activity) :global(.dates) {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}
:global(a:-moz-any-link.activity) :global(.dates) {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}
:global(a:any-link.activity) :global(.dates) {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}
:global(a:-webkit-any-link.activity) :global(.dates) :global(.start_date) {
  margin-right: 0.4em;
}
:global(a:-moz-any-link.activity) :global(.dates) :global(.start_date) {
  margin-right: 0.4em;
}
:global(a:any-link.activity) :global(.dates) :global(.start_date) {
  margin-right: 0.4em;
}
:global(a:-webkit-any-link.activity) :global(.timespan) {
  text-align: right;
  color: magenta;
  display: inline-block;
  margin: 0;
  padding: 0 0 0 1em;
  text-align: right;
  width: 3.6em;
  white-space: nowrap;
  flex-grow: 1;
}
:global(a:-moz-any-link.activity) :global(.timespan) {
  text-align: right;
  color: magenta;
  display: inline-block;
  margin: 0;
  padding: 0 0 0 1em;
  text-align: right;
  width: 3.6em;
  white-space: nowrap;
  flex-grow: 1;
}
:global(a:any-link.activity) :global(.timespan) {
  text-align: right;
  color: magenta;
  display: inline-block;
  margin: 0;
  padding: 0 0 0 1em;
  text-align: right;
  width: 3.6em;
  white-space: nowrap;
  flex-grow: 1;
}
:global(a:-webkit-any-link.activity) :global(.timespan::after) {
  content: " min";
}
:global(a:-moz-any-link.activity) :global(.timespan::after) {
  content: " min";
}
:global(a:any-link.activity) :global(.timespan::after) {
  content: " min";
}
:global(li.marker-blue) :global(a:-webkit-any-link.activity) {
  color: #00dcf2;
}
:global(li.marker-blue) :global(a:-moz-any-link.activity) {
  color: #00dcf2;
}
:global(li.marker-blue) :global(a:any-link.activity) {
  color: #00dcf2;
}
:global(li.marker-fuchsia) :global(a:-webkit-any-link.activity) {
  color: #f9009a;
}
:global(li.marker-fuchsia) :global(a:-moz-any-link.activity) {
  color: #f9009a;
}
:global(li.marker-fuchsia) :global(a:any-link.activity) {
  color: #f9009a;
}
:global(li.marker-green) :global(a:-webkit-any-link.activity) {
  color: #04e900;
}
:global(li.marker-green) :global(a:-moz-any-link.activity) {
  color: #04e900;
}
:global(li.marker-green) :global(a:any-link.activity) {
  color: #04e900;
}
:global(li.marker-yellow) :global(a:-webkit-any-link.activity) {
  color: #ffff00;
}
:global(li.marker-yellow) :global(a:-moz-any-link.activity) {
  color: #ffff00;
}
:global(li.marker-yellow) :global(a:any-link.activity) {
  color: #ffff00;
}
:global(li.marker-salmon) :global(a:-webkit-any-link.activity) {
  color: #ff6272;
}
:global(li.marker-salmon) :global(a:-moz-any-link.activity) {
  color: #ff6272;
}
:global(li.marker-salmon) :global(a:any-link.activity) {
  color: #ff6272;
}
:global(li.marker-orange) :global(a:-webkit-any-link.activity) {
  color: #ff9100;
}
:global(li.marker-orange) :global(a:-moz-any-link.activity) {
  color: #ff9100;
}
:global(li.marker-orange) :global(a:any-link.activity) {
  color: #ff9100;
}
:global(li.marker-purple) :global(a:-webkit-any-link.activity) {
  color: #cd74e2;
}
:global(li.marker-purple) :global(a:-moz-any-link.activity) {
  color: #cd74e2;
}
:global(li.marker-purple) :global(a:any-link.activity) {
  color: #cd74e2;
}
:global(a:-webkit-any-link.activity:hover), :global(li) :global(a:-webkit-any-link.activity:hover) {
  color: white;
}
:global(a:-moz-any-link.activity:hover), :global(li) :global(a:-moz-any-link.activity:hover) {
  color: white;
}
:global(a:any-link.activity:hover), :global(li) :global(a:any-link.activity:hover) {
  color: white;
}

:global(input[type=checkbox]) {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  position: relative;
  width: 26px;
  height: 26px;
  margin-right: 6px;
}
:global(input[type=checkbox]::before), :global(input[type=checkbox]::after) {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 26px;
  height: 26px;
}
:global(input[type=checkbox]::before) {
  border-radius: 50%;
  background-color: #4d4d4d;
}
:global(input[type=checkbox]::after) {
  background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' data-prefix='fal' data-icon='project-diagram' class='svg-inline--fa fa-project-diagram fa-w-20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 640 512'%3E%3Cpath fill='currentColor' d='M592 0h-96c-26.51 0-48 21.49-48 48v32H192V48c0-26.51-21.49-48-48-48H48C21.49 0 0 21.49 0 48v96c0 26.51 21.49 48 48 48h94.86l88.76 150.21c-4.77 7.46-7.63 16.27-7.63 25.79v96c0 26.51 21.49 48 48 48h96c26.51 0 48-21.49 48-48v-96c0-26.51-21.49-48-48-48h-96c-5.2 0-10.11 1.04-14.8 2.57l-83.43-141.18C184.8 172.59 192 159.2 192 144v-32h256v32c0 26.51 21.49 48 48 48h96c26.51 0 48-21.49 48-48V48c0-26.51-21.49-48-48-48zM32 144V48c0-8.82 7.18-16 16-16h96c8.82 0 16 7.18 16 16v96c0 8.82-7.18 16-16 16H48c-8.82 0-16-7.18-16-16zm336 208c8.82 0 16 7.18 16 16v96c0 8.82-7.18 16-16 16h-96c-8.82 0-16-7.18-16-16v-96c0-8.82 7.18-16 16-16h96zm240-208c0 8.82-7.18 16-16 16h-96c-8.82 0-16-7.18-16-16V48c0-8.82 7.18-16 16-16h96c8.82 0 16 7.18 16 16v96z'/%3E%3C/svg%3E");
  background-size: 50% 50%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  -webkit-filter: invert(100%);
          filter: invert(100%);
}
:global(input[type=checkbox]:checked::before) {
  background-color: #2c6ced;
}

:global(label.checkbox) {
  padding: 0 0.8em 0 0;
  cursor: pointer;
  width: 100%;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
:global(label.checkbox:hover) {
  color: white;
  background-color: #474747;
}

:global(ul) {
  background-color: rgba(102, 102, 102, 0.1);
  padding-right: 0.2em;
}
:global(ul.activities), :global(ul.projects) {
  border-radius: 3px;
  border: 1px solid rgba(102, 102, 102, 0.1);
  margin-bottom: 1em;
}
:global(ul.activities) :global(li), :global(ul.projects) :global(li) {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid rgba(191, 191, 191, 0.1);
}
:global(ul.activities) :global(li.marker-blue), :global(ul.projects) :global(li.marker-blue) {
  background-color: rgba(0, 220, 242, 0.15);
}
:global(ul.activities) :global(li.marker-fuchsia), :global(ul.projects) :global(li.marker-fuchsia) {
  background-color: rgba(249, 0, 154, 0.15);
}
:global(ul.activities) :global(li.marker-green), :global(ul.projects) :global(li.marker-green) {
  background-color: rgba(4, 233, 0, 0.15);
}
:global(ul.activities) :global(li.marker-yellow), :global(ul.projects) :global(li.marker-yellow) {
  background-color: rgba(255, 255, 0, 0.15);
}
:global(ul.activities) :global(li.marker-salmon), :global(ul.projects) :global(li.marker-salmon) {
  background-color: rgba(255, 98, 114, 0.15);
}
:global(ul.activities) :global(li.marker-orange), :global(ul.projects) :global(li.marker-orange) {
  background-color: rgba(255, 145, 0, 0.15);
}
:global(ul.activities) :global(li.marker-purple), :global(ul.projects) :global(li.marker-purple) {
  background-color: rgba(205, 116, 226, 0.15);
}
:global(ul.activities) :global(li.project), :global(ul.projects) :global(li.project) {
  position: relative;
  padding: 0.5em 0.4em 0.7em 30px;
  margin-bottom: 0.2em;
  line-height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
:global(ul.activities) :global(li.project::before), :global(ul.activities) :global(li.project::after), :global(ul.projects) :global(li.project::before), :global(ul.projects) :global(li.project::after) {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 26px;
  height: 26px;
}
:global(ul.activities) :global(li.project::before), :global(ul.projects) :global(li.project::before) {
  border-radius: 50%;
  background-color: #2c6ced;
}
:global(ul.activities) :global(li.project::after), :global(ul.projects) :global(li.project::after) {
  background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' data-prefix='fal' data-icon='project-diagram' class='svg-inline--fa fa-project-diagram fa-w-20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 640 512'%3E%3Cpath fill='currentColor' d='M592 0h-96c-26.51 0-48 21.49-48 48v32H192V48c0-26.51-21.49-48-48-48H48C21.49 0 0 21.49 0 48v96c0 26.51 21.49 48 48 48h94.86l88.76 150.21c-4.77 7.46-7.63 16.27-7.63 25.79v96c0 26.51 21.49 48 48 48h96c26.51 0 48-21.49 48-48v-96c0-26.51-21.49-48-48-48h-96c-5.2 0-10.11 1.04-14.8 2.57l-83.43-141.18C184.8 172.59 192 159.2 192 144v-32h256v32c0 26.51 21.49 48 48 48h96c26.51 0 48-21.49 48-48V48c0-26.51-21.49-48-48-48zM32 144V48c0-8.82 7.18-16 16-16h96c8.82 0 16 7.18 16 16v96c0 8.82-7.18 16-16 16H48c-8.82 0-16-7.18-16-16zm336 208c8.82 0 16 7.18 16 16v96c0 8.82-7.18 16-16 16h-96c-8.82 0-16-7.18-16-16v-96c0-8.82 7.18-16 16-16h96zm240-208c0 8.82-7.18 16-16 16h-96c-8.82 0-16-7.18-16-16V48c0-8.82 7.18-16 16-16h96c8.82 0 16 7.18 16 16v96z'/%3E%3C/svg%3E");
  background-size: 50% 50%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  -webkit-filter: invert(100%);
          filter: invert(100%);
}
:global(ul.activities) :global(li.project) :global(.sum), :global(ul.projects) :global(li.project) :global(.sum) {
  color: magenta;
  display: inline-block;
}

:global(select) {
  width: 100%;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

:global(.three) {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

:global(.submit) {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  background: transparent;
  width: 32px;
  height: 32px;
  position: relative;
  cursor: pointer;
  -webkit-transform: translateY(2px);
          transform: translateY(2px);
}
:global(.submit::before), :global(.submit::after) {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
:global(.submit::before) {
  border-radius: 50%;
  background-color: #4d4d4d;
}
:global(.submit::after) {
  background-image: url("data:image/svg+xml,%3Csvg%20aria-hidden%3D%22true%22%20data-prefix%3D%22fal%22%20data-icon%3D%22calendar-plus%22%20class%3D%22svg-inline--fa%20fa-calendar-plus%20fa-w-14%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20448%20512%22%3E%3Cpath%20fill%3D%22currentColor%22%20d%3D%22M320%20332v8c0%206.6-5.4%2012-12%2012h-68v68c0%206.6-5.4%2012-12%2012h-8c-6.6%200-12-5.4-12-12v-68h-68c-6.6%200-12-5.4-12-12v-8c0-6.6%205.4-12%2012-12h68v-68c0-6.6%205.4-12%2012-12h8c6.6%200%2012%205.4%2012%2012v68h68c6.6%200%2012%205.4%2012%2012zm128-220v352c0%2026.5-21.5%2048-48%2048H48c-26.5%200-48-21.5-48-48V112c0-26.5%2021.5-48%2048-48h48V12c0-6.6%205.4-12%2012-12h8c6.6%200%2012%205.4%2012%2012v52h192V12c0-6.6%205.4-12%2012-12h8c6.6%200%2012%205.4%2012%2012v52h48c26.5%200%2048%2021.5%2048%2048zm-416%200v48h384v-48c0-8.8-7.2-16-16-16H48c-8.8%200-16%207.2-16%2016zm384%20352V192H32v272c0%208.8%207.2%2016%2016%2016h352c8.8%200%2016-7.2%2016-16z%22%2F%3E%3C%2Fsvg%3E");
  background-size: 50% 50%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  -webkit-filter: invert(100%);
          filter: invert(100%);
}
:global(.submit:hover::before) {
  background-color: #2c6ced;
}

:global(.formfield) {
  margin: 0.4em 0 0.8em 0;
}

:global(select) {
  color: white;
  font-size: 1em;
  font-weight: 400;
  padding: 0.4em 0.8em;
  border: none;
  border-radius: 5px;
  background-color: #4d4d4d;
}
:global(select:focus) {
  outline: none;
}

:global(input[type=date]),
:global(input[type=time]) {
  border-radius: 5px;
  color: white;
  background-color: #4d4d4d;
  border: none;
  padding: 0.4em 0.4em;
  font-size: 1em;
  font-family: system, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
:global(input[type=date]:focus),
:global(input[type=time]:focus) {
  outline: none;
}

:global(input[type=date]) {
  width: 40%;
}

:global(input[type=time]) {
  width: 24%;
}

:global(p.start_date) {
  border-radius: 5px;
  color: white;
  background-color: #4d4d4d;
  padding: 0.4em 0.8em;
  font-size: 1em;
  margin: 0.1em 0 2px 0;
  width: 33%;
  line-height: 140%;
}

:global(.calendernav) {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
}
:global(.calendernav) :global(h2),
:global(.calendernav) :global(p) {
  margin: 0;
  padding: 0;
}
:global(.calendernav) :global(p) {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
:global(.calendernav) :global(p) :global(button) {
  margin: 0 0.3em;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 50%;
  background-color: transparent;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  -webkit-filter: invert(100%);
          filter: invert(100%);
  opacity: 0.7;
}
:global(.calendernav) :global(p) :global(button:hover) {
  background-color: #999999;
}
:global(.calendernav) :global(p) :global(button:active) {
  opacity: 1;
}
:global(.calendernav) :global(p) :global(button.previous_month), :global(.calendernav) :global(p) :global(button.next_month) {
  background-image: url("data:image/svg+xml,%3Csvg%20aria-hidden%3D%22true%22%20focusable%3D%22false%22%20data-prefix%3D%22fal%22%20data-icon%3D%22chevron-circle-right%22%20class%3D%22svg-inline--fa%20fa-chevron-circle-right%20fa-w-16%22%20role%3D%22img%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20512%20512%22%3E%3Cpath%20fill%3D%22currentColor%22%20d%3D%22M8%20256c0%20137%20111%20248%20248%20248s248-111%20248-248S393%208%20256%208%208%20119%208%20256zM256%2040c118.7%200%20216%2096.1%20216%20216%200%20118.7-96.1%20216-216%20216-118.7%200-216-96.1-216-216%200-118.7%2096.1-216%20216-216zm86.6%20224.5l-115.1%20115c-4.7%204.7-12.3%204.7-17%200l-7.1-7.1c-4.7-4.7-4.7-12.3%200-17L303%20256l-99.5-99.5c-4.7-4.7-4.7-12.3%200-17l7.1-7.1c4.7-4.7%2012.3-4.7%2017%200l115.1%20115c4.6%204.8%204.6%2012.4-.1%2017.1z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E");
}
:global(.calendernav) :global(p) :global(button.previous_month) {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
:global(.calendernav) :global(p) :global(button.today) {
  background-image: url("data:image/svg+xml,%3Csvg%20aria-hidden%3D%22true%22%20focusable%3D%22false%22%20data-prefix%3D%22fal%22%20data-icon%3D%22dot-circle%22%20class%3D%22svg-inline--fa%20fa-dot-circle%20fa-w-16%22%20role%3D%22img%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20512%20512%22%3E%3Cpath%20fill%3D%22currentColor%22%20d%3D%22M256%208C119.033%208%208%20119.033%208%20256s111.033%20248%20248%20248%20248-111.033%20248-248S392.967%208%20256%208zm0%20464c-118.663%200-216-96.055-216-216%200-118.663%2096.055-216%20216-216%20118.663%200%20216%2096.055%20216%20216%200%20118.663-96.055%20216-216%20216zm0-296c-44.183%200-80%2035.817-80%2080s35.817%2080%2080%2080%2080-35.817%2080-80-35.817-80-80-80zm0%20128c-26.467%200-48-21.533-48-48s21.533-48%2048-48%2048%2021.533%2048%2048-21.533%2048-48%2048z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E");
}
:global(.calendernav) :global(p) :global(a:-webkit-any-link) {
  display: inline-block;
  position: relative;
  overflow: hidden;
  line-height: 2000%;
  width: 16px;
  height: 16px;
  margin: 0 4px;
}
:global(.calendernav) :global(p) :global(a:-moz-any-link) {
  display: inline-block;
  position: relative;
  overflow: hidden;
  line-height: 2000%;
  width: 16px;
  height: 16px;
  margin: 0 4px;
}
:global(.calendernav) :global(p) :global(a:any-link) {
  display: inline-block;
  position: relative;
  overflow: hidden;
  line-height: 2000%;
  width: 16px;
  height: 16px;
  margin: 0 4px;
}
:global(.calendernav) :global(p) :global(a:-webkit-any-link) :global(span) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 50%;
  -webkit-filter: invert(100%);
          filter: invert(100%);
}
:global(.calendernav) :global(p) :global(a:-moz-any-link) :global(span) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 50%;
  filter: invert(100%);
}
:global(.calendernav) :global(p) :global(a:any-link) :global(span) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 50%;
  -webkit-filter: invert(100%);
          filter: invert(100%);
}
:global(.calendernav) :global(p) :global(a:-webkit-any-link) :global(span.previous_month), :global(.calendernav) :global(p) :global(a:-webkit-any-link) :global(span.next_month) {
  background-image: url("data:image/svg+xml,%3Csvg%20aria-hidden%3D%22true%22%20focusable%3D%22false%22%20data-prefix%3D%22far%22%20data-icon%3D%22chevron-circle-right%22%20class%3D%22svg-inline--fa%20fa-chevron-circle-right%20fa-w-16%22%20role%3D%22img%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20512%20512%22%3E%3Cpath%20fill%3D%22currentColor%22%20d%3D%22M8%20256c0%20137%20111%20248%20248%20248s248-111%20248-248S393%208%20256%208%208%20119%208%20256zm448%200c0%20110.5-89.5%20200-200%20200S56%20366.5%2056%20256%20145.5%2056%20256%2056s200%2089.5%20200%20200zm-107.5%208.5L225.7%20387.3c-4.7%204.7-12.3%204.7-17%200l-22.6-22.6c-4.7-4.7-4.7-12.3%200-17l91.7-91.7-91.7-91.7c-4.7-4.7-4.7-12.3%200-17l22.6-22.6c4.7-4.7%2012.3-4.7%2017%200l122.8%20122.8c4.7%204.7%204.7%2012.3%200%2017z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E");
}
:global(.calendernav) :global(p) :global(a:-moz-any-link) :global(span.previous_month), :global(.calendernav) :global(p) :global(a:-moz-any-link) :global(span.next_month) {
  background-image: url("data:image/svg+xml,%3Csvg%20aria-hidden%3D%22true%22%20focusable%3D%22false%22%20data-prefix%3D%22far%22%20data-icon%3D%22chevron-circle-right%22%20class%3D%22svg-inline--fa%20fa-chevron-circle-right%20fa-w-16%22%20role%3D%22img%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20512%20512%22%3E%3Cpath%20fill%3D%22currentColor%22%20d%3D%22M8%20256c0%20137%20111%20248%20248%20248s248-111%20248-248S393%208%20256%208%208%20119%208%20256zm448%200c0%20110.5-89.5%20200-200%20200S56%20366.5%2056%20256%20145.5%2056%20256%2056s200%2089.5%20200%20200zm-107.5%208.5L225.7%20387.3c-4.7%204.7-12.3%204.7-17%200l-22.6-22.6c-4.7-4.7-4.7-12.3%200-17l91.7-91.7-91.7-91.7c-4.7-4.7-4.7-12.3%200-17l22.6-22.6c4.7-4.7%2012.3-4.7%2017%200l122.8%20122.8c4.7%204.7%204.7%2012.3%200%2017z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E");
}
:global(.calendernav) :global(p) :global(a:any-link) :global(span.previous_month), :global(.calendernav) :global(p) :global(a:any-link) :global(span.next_month) {
  background-image: url("data:image/svg+xml,%3Csvg%20aria-hidden%3D%22true%22%20focusable%3D%22false%22%20data-prefix%3D%22far%22%20data-icon%3D%22chevron-circle-right%22%20class%3D%22svg-inline--fa%20fa-chevron-circle-right%20fa-w-16%22%20role%3D%22img%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20512%20512%22%3E%3Cpath%20fill%3D%22currentColor%22%20d%3D%22M8%20256c0%20137%20111%20248%20248%20248s248-111%20248-248S393%208%20256%208%208%20119%208%20256zm448%200c0%20110.5-89.5%20200-200%20200S56%20366.5%2056%20256%20145.5%2056%20256%2056s200%2089.5%20200%20200zm-107.5%208.5L225.7%20387.3c-4.7%204.7-12.3%204.7-17%200l-22.6-22.6c-4.7-4.7-4.7-12.3%200-17l91.7-91.7-91.7-91.7c-4.7-4.7-4.7-12.3%200-17l22.6-22.6c4.7-4.7%2012.3-4.7%2017%200l122.8%20122.8c4.7%204.7%204.7%2012.3%200%2017z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E");
}
:global(.calendernav) :global(p) :global(a:-webkit-any-link) :global(span.previous_month) {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
:global(.calendernav) :global(p) :global(a:-moz-any-link) :global(span.previous_month) {
  transform: rotate(180deg);
}
:global(.calendernav) :global(p) :global(a:any-link) :global(span.previous_month) {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
:global(.calendernav) :global(p) :global(a:-webkit-any-link) :global(span.today) {
  background-image: url("data:image/svg+xml,%3Csvg%20aria-hidden%3D%22true%22%20focusable%3D%22false%22%20data-prefix%3D%22far%22%20data-icon%3D%22dot-circle%22%20class%3D%22svg-inline--fa%20fa-dot-circle%20fa-w-16%22%20role%3D%22img%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20512%20512%22%3E%3Cpath%20fill%3D%22currentColor%22%20d%3D%22M256%2056c110.532%200%20200%2089.451%20200%20200%200%20110.532-89.451%20200-200%20200-110.532%200-200-89.451-200-200%200-110.532%2089.451-200%20200-200m0-48C119.033%208%208%20119.033%208%20256s111.033%20248%20248%20248%20248-111.033%20248-248S392.967%208%20256%208zm0%20168c-44.183%200-80%2035.817-80%2080s35.817%2080%2080%2080%2080-35.817%2080-80-35.817-80-80-80z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E");
}
:global(.calendernav) :global(p) :global(a:-moz-any-link) :global(span.today) {
  background-image: url("data:image/svg+xml,%3Csvg%20aria-hidden%3D%22true%22%20focusable%3D%22false%22%20data-prefix%3D%22far%22%20data-icon%3D%22dot-circle%22%20class%3D%22svg-inline--fa%20fa-dot-circle%20fa-w-16%22%20role%3D%22img%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20512%20512%22%3E%3Cpath%20fill%3D%22currentColor%22%20d%3D%22M256%2056c110.532%200%20200%2089.451%20200%20200%200%20110.532-89.451%20200-200%20200-110.532%200-200-89.451-200-200%200-110.532%2089.451-200%20200-200m0-48C119.033%208%208%20119.033%208%20256s111.033%20248%20248%20248%20248-111.033%20248-248S392.967%208%20256%208zm0%20168c-44.183%200-80%2035.817-80%2080s35.817%2080%2080%2080%2080-35.817%2080-80-35.817-80-80-80z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E");
}
:global(.calendernav) :global(p) :global(a:any-link) :global(span.today) {
  background-image: url("data:image/svg+xml,%3Csvg%20aria-hidden%3D%22true%22%20focusable%3D%22false%22%20data-prefix%3D%22far%22%20data-icon%3D%22dot-circle%22%20class%3D%22svg-inline--fa%20fa-dot-circle%20fa-w-16%22%20role%3D%22img%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20512%20512%22%3E%3Cpath%20fill%3D%22currentColor%22%20d%3D%22M256%2056c110.532%200%20200%2089.451%20200%20200%200%20110.532-89.451%20200-200%20200-110.532%200-200-89.451-200-200%200-110.532%2089.451-200%20200-200m0-48C119.033%208%208%20119.033%208%20256s111.033%20248%20248%20248%20248-111.033%20248-248S392.967%208%20256%208zm0%20168c-44.183%200-80%2035.817-80%2080s35.817%2080%2080%2080%2080-35.817%2080-80-35.817-80-80-80z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E");
}

:global(table.calender) {
  border-collapse: collapse;
  width: 100%;
}
:global(table.calender) :global(th),
:global(table.calender) :global(td) {
  border: 1px solid black;
  text-align: right;
  background-color: #333333;
}
:global(table.calender) :global(th.weekend),
:global(table.calender) :global(td.weekend) {
  background-color: rgba(92, 92, 92, 0.2);
  color: rgba(255, 255, 255, 0.5);
}
:global(table.calender) :global(th.weekend) :global(a:-webkit-any-link), :global(table.calender) :global(td.weekend) :global(a:-webkit-any-link) {
  color: rgba(255, 255, 255, 0.5);
}
:global(table.calender) :global(th.weekend) :global(a:-moz-any-link), :global(table.calender) :global(td.weekend) :global(a:-moz-any-link) {
  color: rgba(255, 255, 255, 0.5);
}
:global(table.calender) :global(th.weekend) :global(a:any-link),
:global(table.calender) :global(td.weekend) :global(a:any-link) {
  color: rgba(255, 255, 255, 0.5);
}
:global(table.calender) :global(th.weekend) :global(a:-webkit-any-link.today), :global(table.calender) :global(td.weekend) :global(a:-webkit-any-link.today) {
  color: magenta;
}
:global(table.calender) :global(th.weekend) :global(a:-moz-any-link.today), :global(table.calender) :global(td.weekend) :global(a:-moz-any-link.today) {
  color: magenta;
}
:global(table.calender) :global(th.weekend) :global(a:any-link.today),
:global(table.calender) :global(td.weekend) :global(a:any-link.today) {
  color: magenta;
}
:global(table.calender) :global(th) {
  padding: 0.3em 0.4em;
}
:global(table.calender) :global(td) :global(a:-webkit-any-link) {
  padding: 0.3em 0.4em;
}
:global(table.calender) :global(td) :global(a:-moz-any-link) {
  padding: 0.3em 0.4em;
}
:global(table.calender) :global(td) :global(a:any-link) {
  padding: 0.3em 0.4em;
}
:global(table.calender) :global(td) :global(a:-webkit-any-link.othermonth) {
  color: gray;
}
:global(table.calender) :global(td) :global(a:-moz-any-link.othermonth) {
  color: gray;
}
:global(table.calender) :global(td) :global(a:any-link.othermonth) {
  color: gray;
}
:global(table.calender) :global(td) :global(a:-webkit-any-link.today) {
  color: magenta;
}
:global(table.calender) :global(td) :global(a:-moz-any-link.today) {
  color: magenta;
}
:global(table.calender) :global(td) :global(a:any-link.today) {
  color: magenta;
}
:global(table.calender) :global(td) :global(a:-webkit-any-link.selected) {
  background-color: #2c6ced;
  color: white;
}
:global(table.calender) :global(td) :global(a:-moz-any-link.selected) {
  background-color: #2c6ced;
  color: white;
}
:global(table.calender) :global(td) :global(a:any-link.selected) {
  background-color: #2c6ced;
  color: white;
}

:global(.dash) {
  display: inline-block;
  margin: 0 0.4em;
}

:global(.alert) {
  margin-top: 2em;
  padding: 3em 0.8em;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  background-color: #ffc400;
  color: #272727;
  font-weight: bold;
}
:global(.alert) :global(p) {
  margin: 0;
}
:global(.alert::before), :global(.alert::after) {
  content: "";
  position: absolute;
  width: 100%;
  height: 2em;
  background: repeating-linear-gradient(135deg, #535353, #535353 10px, #ffc400 10px, #ffc400 20px);
}
:global(.alert::before) {
  top: 0;
  left: 0;
}
:global(.alert::after) {
  bottom: 0;
  left: 0;
}

:global(.dayoverview) {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 1px;
  margin-bottom: 3em;
}
:global(.dayoverview) :global(li) {
  background-color: #333333;
  height: 2em;
  position: relative;
  border-radius: 3px;
}
:global(.dayoverview) :global(li) :global(span) {
  position: absolute;
  top: 110%;
  left: 0;
  display: none;
  border-left: 1px solid #474747;
  padding-left: 0.4em;
  font-size: 0.8em;
}
:global(.dayoverview) :global(li:nth-child(8n+1)) :global(span) {
  display: inline-block;
  white-space: nowrap;
}
:global(.dayoverview) :global(li.marker-blue) {
  background-color: #00dcf2;
}
:global(.dayoverview) :global(li.marker-fuchsia) {
  background-color: #f9009a;
}
:global(.dayoverview) :global(li.marker-green) {
  background-color: #04e900;
}
:global(.dayoverview) :global(li.marker-yellow) {
  background-color: #ffff00;
}
:global(.dayoverview) :global(li.marker-salmon) {
  background-color: #ff6272;
}
:global(.dayoverview) :global(li.marker-orange) {
  background-color: #ff9100;
}</style>

<header>
  <h1>
    <span>Tempo</span>
    <span>{#if users_unique_id}{$user.firstname} {$user.lastname}{/if}</span>
  </h1>
</header>
<main>
  {#if users_unique_id}
    <Calendar />
    <EditActivities />
    <ActivitiesList />
    <ProjectsDailySums />
    <ProjectsMonthlySums />
  {:else}
    <div class="alert">
      <p>Um fortzufahren, wird eine gültige Benutzer-ID benötigt.</p>
    </div>
  {/if}
</main>
<Revision {version} />
